import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

export function useActivationReferralShareMessage(referralCode: string) {
  const { messageTemplate } = useCbhFlag(CbhFeatureFlag.ACTIVATION_REFERRAL_SHARE_MESSAGE_CONFIG, {
    defaultValue: {
      messageTemplate:
        "Hey! I work through Clipboard, and I love it! Use my referral code {{referral_code}} when you sign up and you'll get a bonus after you work your first shift!",
    },
  });

  return messageTemplate.replace("{{referral_code}}", referralCode);
}
