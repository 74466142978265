import { z } from "zod";

import { attendanceScoreProfileResponseSchema } from "../AttendanceScore/api/useGetAttendanceScoreProfile";
import { CbhFeatureFlag, useCbhFlag } from "../FeatureFlags";

const maybeValidReliabilityScoreDataSchema = attendanceScoreProfileResponseSchema.pick({
  reliabilityScore: true,
  reliabilityScoreRank: true,
});

const validReliabilityScoreDataSchema = maybeValidReliabilityScoreDataSchema.extend({
  reliabilityScore: z.number(),
  reliabilityScoreRank: z.object({
    rank: z.number(),
    outOf: z.number(),
  }),
});

export type MaybeValidReliabilityScoreData = z.infer<typeof maybeValidReliabilityScoreDataSchema>;
export type ValidReliabilityScoreData = z.infer<typeof validReliabilityScoreDataSchema>;

interface UseReliabilityRankingExperimentDisabledResponse {
  enabled: false;
  data: undefined;
}

interface UseReliabilityRankingExperimentEnabledResponse {
  enabled: true;
  data: ValidReliabilityScoreData;
}

type UseReliabilityRankingExperimentResponse =
  | UseReliabilityRankingExperimentDisabledResponse
  | UseReliabilityRankingExperimentEnabledResponse;

export function useReliabilityRankingExperiment(
  data?: MaybeValidReliabilityScoreData
): UseReliabilityRankingExperimentResponse {
  const isExperimentEnabled = useCbhFlag(CbhFeatureFlag.RELIABILITY_RANKING_EXPERIMENT, {
    defaultValue: false,
  });
  const requiredData = validReliabilityScoreDataSchema.safeParse(data);

  if (!isExperimentEnabled || !requiredData.success) {
    return {
      enabled: false,
      data: undefined,
    };
  }

  return {
    enabled: true,
    data: requiredData.data,
  };
}
