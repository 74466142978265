import { get } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { paginationSchema } from "@src/appV2/lib/api/types";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { QuestionTag, Reaction } from "../types";

export enum WorkerProfilePictureStatus {
  EXISTS_IN_FILE_STORAGE = "EXISTS_IN_FILE_STORAGE",
  DOES_NOT_EXIST = "DOES_NOT_EXIST",
}

const workerInfoSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  profilePictureStatus: z.nativeEnum(WorkerProfilePictureStatus).optional(),
  qualification: z.string().optional(),
});

export type WorkerInfoType = z.infer<typeof workerInfoSchema>;

const workplaceCommentAttributesSchema = z.object({
  text: z.string(),
  workerInfo: workerInfoSchema,
  workerReaction: z.nativeEnum(Reaction).nullable(),
  aggregates: z.object({
    totalLikes: z.number(),
    totalReplies: z.number(),
  }),
  createdAt: z.string(),
});

export type WorkplaceCommentAttributesType = z.infer<typeof workplaceCommentAttributesSchema>;

export const workplaceCommentSchema = z.object({
  id: z.string(),
  attributes: workplaceCommentAttributesSchema,
});

export type WorkplaceCommentType = z.infer<typeof workplaceCommentSchema>;

export const workplaceCommentsResponseSchema = z.object({
  data: z.array(workplaceCommentSchema),
  links: z.object({
    next: z.string().optional(),
  }),
});

export type WorkplaceCommentsResponse = z.infer<typeof workplaceCommentsResponseSchema>;

export const workplaceCommentsRequestSchema = z.object({
  filter: z
    .object({
      parentCommentId: z.string().optional(),
      questionId: z.string().optional(),
      questionTags: z.array(z.nativeEnum(QuestionTag)).optional(),
    })
    .optional(),
  page: paginationSchema.optional(),
});

export type WorkplaceCommentsRequest = z.infer<typeof workplaceCommentsRequestSchema>;

export type WorkplaceCommentsParams = WorkplaceCommentsRequest & {
  workplaceId: string;
};

export function getWorkplaceCommentsUrl(workplaceId: string): string {
  return `${environmentConfig.REACT_APP_SHIFT_REVIEW_API_URL}/workplaces/${workplaceId}/comments`;
}

export function getWorkplaceCommentsQueryKey(params: WorkplaceCommentsParams) {
  const { workplaceId, ...workplaceCommentsRequestParams } = params;
  return [getWorkplaceCommentsUrl(workplaceId), workplaceCommentsRequestParams];
}

export function useWorkplaceComments(
  params: WorkplaceCommentsParams,
  options: UseQueryOptions<WorkplaceCommentType[], AxiosError> = {}
): UseQueryResult<WorkplaceCommentType[]> {
  const { workplaceId, ...workplaceCommentsRequestParams } = params;
  return useQuery({
    queryKey: getWorkplaceCommentsQueryKey(params),
    queryFn: async () => {
      const response = await get({
        url: getWorkplaceCommentsUrl(workplaceId),
        queryParams: workplaceCommentsRequestParams,
        responseSchema: workplaceCommentsResponseSchema,
        requestSchema: workplaceCommentsRequestSchema,
      });

      return response.data.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_REVIEW_COMMENTS_FAILURE,
    },
    ...options,
  });
}
